// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css");

// Fontawesome
// @import "~@fortawesome/fontawesome-free/scss/fontawesome";
// @import "~@fortawesome/fontawesome-free/scss/regular";
// @import "~@fortawesome/fontawesome-free/scss/solid";
// @import "~@fortawesome/fontawesome-free/scss/brands";

